import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Step,
  Stepper,
  Stack,
  Typography,
  StepContent,
  StepLabel,
} from "@mui/material";
import BandsFields from "../components/bandsComponents/BandsFields";
import BandsSpecialInstructions from "../components/bandsComponents/BandsSpecialInstructions";
import CheckIcon from "@mui/icons-material/Check";

const steps = ["Band Details", "Stone Details", "Ring Treatments"];

function BandStepper({
  builder,
  complex,
  complex1,
  complex2,
  complex3,
  product,
  stoneProviderFlag,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set());
  const theme = useTheme();

  const fieldMap = {
    materialField: complex1.find((item) => item.label === "Material"),
    ringSizeField: complex.find((item) => item.label === "Ring Size"),
    stoneSizeField: complex1.find(
      (item) => item.label === "Stone Size (ct - mm)"
    ),
    stoneQuantityField: complex1.find(
      (item) => item.label === "Stone Quantity"
    ),
    widthField: complex1.find((item) => item.label === "Width (mm)"),
    stoneProviderField: complex1.find(
      (item) => item.label === "Stone Provided By"
    ),
    stoneQualityField: complex1.find(
      (item) => item.label === "Stone Quality and Type"
    ),
    finishField: complex1.find((item) => item.label === "Finish"),
    rhodiumPlatingField: complex1.find(
      (item) => item.label === "Rhodium Plating"
    ),
    karatStampField: complex.find((item) => item.label === "Karat Stamp"),
    trademarkField: complex.find((item) => item.label === "Trademark"),
  };

  const handleNext = () => {
    setCompletedSteps((prev) => new Set([...prev, activeStep]));
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCompletedSteps((prev) => {
      const newSet = new Set(prev);
      newSet.delete(activeStep - 1);
      return newSet;
    });
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompletedSteps(new Set());
  };

  const renderStepContent = (activeStep) => (
    <Stack spacing={2}>
      {/* Step 0 */}
      <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
        {fieldMap.materialField && (
          <BandsFields field={fieldMap.materialField} />
        )}
        {builder?.map((field) => (
          <BandsFields field={field} key={field.name} />
        ))}
        {fieldMap.ringSizeField && (
          <BandsFields field={fieldMap.ringSizeField} />
        )}
      </Box>

      {/* Step 1 */}
      <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
        {fieldMap.stoneSizeField && (
          <BandsFields field={fieldMap.stoneSizeField} />
        )}
        {fieldMap.stoneQuantityField && (
          <BandsFields field={fieldMap.stoneQuantityField} />
        )}
        {product !== "A" && fieldMap.widthField && (
          <BandsFields field={fieldMap.widthField} />
        )}
        {fieldMap.stoneProviderField && (
          <BandsFields field={fieldMap.stoneProviderField} />
        )}
        {stoneProviderFlag && fieldMap.stoneQualityField && (
          <BandsFields field={fieldMap.stoneQualityField} />
        )}
      </Box>

      {/* Step 2 */}
      <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
        {fieldMap.finishField && <BandsFields field={fieldMap.finishField} />}
        {fieldMap.rhodiumPlatingField && (
          <BandsFields field={fieldMap.rhodiumPlatingField} />
        )}
        {fieldMap.karatStampField && (
          <BandsFields field={fieldMap.karatStampField} />
        )}
        {fieldMap.trademarkField && (
          <BandsFields field={fieldMap.trademarkField} />
        )}
        <BandsSpecialInstructions />
      </Box>

      {/* Step 3 */}
      <Box sx={{ display: activeStep === 3 ? "block" : "none" }}>
        {complex2?.map((field) => (
          <BandsFields field={field} key={field.name} />
        ))}
      </Box>

      {/* Step 4 */}
      <Box sx={{ display: activeStep === 4 ? "block" : "none" }}>
        {complex3?.map((field) => (
          <BandsFields field={field} key={field.name} />
        ))}
      </Box>
    </Stack>
  );

  const CustomStepIcon = ({ icon, active }) => {
    const isCompleted = completedSteps.has(icon - 1);
    return (
      <Box
        sx={{
          width: 30,
          height: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: isCompleted
            ? theme.palette.success.main
            : active
            ? theme.palette.primary.main
            : theme.palette.secondary.dark,
          color: theme.palette.primary.contrastText,
          fontSize: "1.5rem",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {isCompleted ? <CheckIcon /> : icon}
      </Box>
    );
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Typography sx={{ fontFamily: theme.typography.body1 }}>
                {label}
              </Typography>
            </StepLabel>
            <StepContent>
              {renderStepContent(index)}
              <Stack direction="row" gap={2}>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {index === steps.length - 1 ? "Finish" : "Continue"}
                </Button>
                <Button
                  disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </Stack>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Box sx={{ mt: 4 }}>
          <Stack spacing={2}>
            <Typography variant="h6">All steps completed!</Typography>
            <Button onClick={handleReset} variant="contained">
              Restart
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default BandStepper;
